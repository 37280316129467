import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './taxesPopup.style';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/root.reducer';
import SVGInfo from 'src/assets/icons/SVGInfo';
import SVGCross from 'src/assets/icons/SVGCross';
import { useState, useEffect } from 'react';
import i18n from 'src/common/i18n/i18n';

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText?: string;
  hideBackdrop?: boolean;
  title?: string;
}

function TaxesPopup({
  onClose,
  open,
  buttonText,
  hideBackdrop,
}: Props): JSX.Element {

  const theme = useTheme();
  const style = makeStyle(theme);
  const { t } = useTranslation();
  const taxesInfo = useSelector((state: RootState) => state.app.config.support?.taxes?.info);
  const [translatedTaxesText, setTranslatedTaxesText] = useState<string>("");

  useEffect(() => {
    setTranslatedTaxesText(taxesInfo[i18n.language])
  }, [])


  return (
    <Popover
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop ?? true}
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Box sx={style.boxInfo}>
          <SVGInfo color="#F79009" />
        </Box>

        <Button
          disableRipple
          onClick={onClose}
          sx={style.crossButton}
        >
          <SVGCross width="28" height="28" color="#98A2B3" />
        </Button>
      </Box>

      <Box sx={style.supportPopupBox}>
        <Typography sx={style.title}>{t('tax')}</Typography>

        <Typography sx={style.supportPopupTxt}>
          {translatedTaxesText}
        </Typography>
        
      </Box>

      <Box sx={style.boxButton}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          sx={style.button}
          onClick={onClose}
        >
          {buttonText || 'OK'}
        </Button>
      </Box>
    </Popover>
  );
}

export default TaxesPopup;
