import { IPlayerBetsRequest } from 'src/models/ticketing/ticket.model';
import { ENV } from '../../env';
import kp_axios, { PLATFORM_LOTTERY } from './api.koralplay.service';

//=============================================================================
// API CALLS
//=============================================================================

const TICKETING_URL = ENV.API_URL + '/ticketing-reading/rest';
const ENGINE_URL = ENV.API_URL + ENV.ENGINE_PATH;

export const getPlayerBets = async (params: IPlayerBetsRequest) => {
  return kp_axios.get(`${TICKETING_URL}/v1/online-player/bets`, { params });
}

export const getInstantDrawResults = async (drawIdList: string[]) => {
  const body = { gameSessionIds: drawIdList }
  const params = { platform: PLATFORM_LOTTERY };
  return kp_axios.patch(`${ENGINE_URL}/v2-a/webplayer/games/lottery/instant/draws`, body, { params });
}

export const getMutualDrawResults = async (gameId: number, drawIdList: string[]) => {
  //TODO: api multi-tirage inexistante - en attendant, appel des api mono-tirages
  return Promise.all(
    drawIdList.map(drawId => kp_axios
      .get(`${ENGINE_URL}/v2/webplayer/games/${gameId}/lottery/mutual/draws/${drawId}`)
      .then(res => res.data))
  ).then(retList => ({ data: { success: true, draws: retList } }))
  // const body = { gameSessionIds: drawIdList }
  // const params = { platform: PLATFORM_LOTTERY };
  // return kp_axios.patch(`${ENGINE_URL}/v2-a/webplayer/games/lottery/mutual/draws`, body, { params });
}

// export const getPlayerBets = async (params: IPlayerBetsRequest) => {
//   // if (params.waitingBets) return getPlayerBetsFINAL(params);
//   // if (parseInt("1") == 1) { params.waitingBets = true; return getPlayerBetsFINAL(params); }
//   const passedBets = [
//     {
//       gameId: 1,
//       bookingId: "66debf22c78c98089aea3723",
//       settlementStatus: "WIN",
//       paymentStatus: "PAYMENT_BLOCKED",
//       gameBetId: "bet001",
//       price: 100,
//       player: {
//         userId: "user15362"
//       },
//       creationDate: "2024-07-28T10:00:00Z",
//       expectedSettlementDate: "2024-07-30T10:00:00Z",
//       gameTicketReference: "baa24ab4-0724-4d6c-8e29-f7cfba24c9c4",
//       betDetails: {
//         betId: 1,
//         gameId: 1,
//         gameType: "instantLottery",
//         creationTime: "2024-07-28T09:00:00Z",
//         price: 100,
//         stake: 100,
//         earn: 500,
//         listOfBonusId: [],
//         locked: false,
//         taxAmount: 260,
//         betMode: "Standard",
//         numberOfDraws: 1,
//         betTypeId: 6100033,
//         amtTotalWon: 2500,
//         winNumber: [8, 88, 2],
//         listOfBetGrids: [
//           {
//             gridName: "5/90",
//             listOfLottoNumbers: [2, 8, 88],
//             usedAdditionalNumbers: 0
//           }
//         ],
//         listOfStatusAndDraws: [
//           {
//             status: "wait",
//             draw: {
//               drawId: "717645",
//               closingDate: "2024-08-03T00:28",
//               frequency: "DAILY",
//               dailyReference: 5,
//               drawingDate: "2024-08-03T00:30",
//               drawingName: "Nkobo",
//             },
//             taxAmount: 260
//           }
//         ]
//       }
//     },
//     {
//       gameId: 1,
//       bookingId: "66debf22c78c98089aea3723",
//       settlementStatus: "WIN",
//       paymentStatus: "PAYMENT_SUCCESS",
//       gameBetId: "bet001",
//       price: 100,
//       player: {
//         userId: "user15362"
//       },
//       creationDate: "2024-07-28T10:00:00Z",
//       expectedSettlementDate: "2024-07-30T10:00:00Z",
//       gameTicketReference: "baa24ab4-0724-4d6c-8e29-f7cfba24c9c4",
//       betDetails: {
//         betId: 1,
//         gameId: 1,
//         gameType: "instantLottery",
//         creationTime: "2024-07-28T09:00:00Z",
//         price: 100,
//         stake: 100,
//         earn: 500,
//         listOfBonusId: [],
//         locked: false,
//         taxAmount: 260,
//         betMode: "Standard",
//         numberOfDraws: 1,
//         betTypeId: 6100033,
//         amtTotalWon: 2500,
//         winNumber: [6, 10, 79],
//         listOfBetGrids: [
//           {
//             gridName: "5/90",
//             listOfLottoNumbers: [6, 68, 79],
//             usedAdditionalNumbers: 0
//           }
//         ],
//         listOfStatusAndDraws: [
//           {
//             status: "wait",
//             draw: {
//               drawId: "717626",
//               closingDate: "2024-08-03T00:28",
//               frequency: "DAILY",
//               dailyReference: 5,
//               drawingDate: "2024-08-03T00:30",
//               drawingName: "Nkobo",
//             },
//             taxAmount: 260
//           }
//         ]
//       }
//     },
//     {
//       gameId: 1,
//       bookingId: "66debf22c78c98089aea3723",
//       settlementStatus: "CANCELED_TICKET",
//       paymentStatus: "PAYMENT_SUCCESS",
//       gameBetId: "bet001",
//       price: 100,
//       player: {
//         userId: "user15362"
//       },
//       creationDate: "2024-07-28T10:00:00Z",
//       expectedSettlementDate: "2024-07-30T10:00:00Z",
//       gameTicketReference: "baa24ab4-0724-4d6c-8e29-f7cfba24c9c4",
//       betDetails: {
//         betId: 1,
//         gameId: 1,
//         gameType: "instantLottery",
//         creationTime: "2024-07-28T09:00:00Z",
//         price: 100,
//         stake: 100,
//         earn: 500,
//         listOfBonusId: [],
//         locked: false,
//         taxAmount: 260,
//         betMode: "Standard",
//         numberOfDraws: 1,
//         betTypeId: 6100033,
//         amtTotalWon: 2500,
//         winNumber: [],
//         listOfBetGrids: [
//           {
//             gridName: "5/90",
//             listOfLottoNumbers: [6, 68, 79],
//             usedAdditionalNumbers: 0
//           }
//         ],
//         listOfStatusAndDraws: [
//           {
//             status: "wait",
//             draw: {
//               drawId: "717609",
//               closingDate: "2024-08-03T00:28",
//               frequency: "DAILY",
//               dailyReference: 5,
//               drawingDate: "2024-08-03T00:30",
//               drawingName: "Nkobo",
//             },
//             taxAmount: 260
//           }
//         ]
//       }
//     },
//     {
//       gameId: 1,
//       bookingId: "66debf22c78c98089aea3723",
//       settlementStatus: "LOST",
//       paymentStatus: "PAYMENT_NOT_NEEDED",
//       gameBetId: "bet001",
//       price: 100,
//       player: {
//         userId: "user15362"
//       },
//       creationDate: "2024-07-28T10:00:00Z",
//       expectedSettlementDate: "2024-07-30T10:00:00Z",
//       gameTicketReference: "baa24ab4-0724-4d6c-8e29-f7cfba24c9c4",
//       betDetails: {
//         betId: 1,
//         gameId: 1,
//         gameType: "instantLottery",
//         creationTime: "2024-07-28T09:00:00Z",
//         price: 100,
//         stake: 100,
//         earn: 500,
//         listOfBonusId: [],
//         locked: false,
//         taxAmount: 260,
//         betMode: "Standard",
//         numberOfDraws: 1,
//         betTypeId: 6100033,
//         amtTotalWon: 2500,
//         winNumber: [8, 2, 57],
//         listOfBetGrids: [
//           {
//             gridName: "5/90",
//             listOfLottoNumbers: [6, 68, 79],
//             usedAdditionalNumbers: 0
//           }
//         ],
//         listOfStatusAndDraws: [
//           {
//             status: "wait",
//             draw: {
//               drawId: "717590",
//               closingDate: "2024-08-03T00:28",
//               frequency: "DAILY",
//               dailyReference: 5,
//               drawingDate: "2024-08-03T00:30",
//               drawingName: "Nkobo",
//             },
//             taxAmount: 260
//           }
//         ]
//       }
//     }
//     ,
//     {
//       gameId: 1,
//       bookingId: "66debf22c78c98089aea3723",
//       settlementStatus: "CANCELED_TICKET",
//       paymentStatus: "PAYMENT_BLOCKED",
//       gameBetId: "bet001",
//       price: 100,
//       player: {
//         userId: "user15362"
//       },
//       creationDate: "2024-07-28T10:00:00Z",
//       expectedSettlementDate: "2024-07-30T10:00:00Z",
//       gameTicketReference: "baa24ab4-0724-4d6c-8e29-f7cfba24c9c4",
//       betDetails: {
//         betId: 1,
//         gameId: 1,
//         gameType: "instantLottery",
//         creationTime: "2024-07-28T09:00:00Z",
//         price: 100,
//         stake: 100,
//         earn: 500,
//         listOfBonusId: [],
//         locked: false,
//         taxAmount: 260,
//         betMode: "Standard",
//         numberOfDraws: 1,
//         betTypeId: 6100033,
//         amtTotalWon: 2500,
//         winNumber: [],
//         listOfBetGrids: [
//           {
//             gridName: "5/90",
//             listOfLottoNumbers: [55, 3, 7],
//             usedAdditionalNumbers: 0
//           }
//         ],
//         listOfStatusAndDraws: [
//           {
//             status: "wait",
//             draw: {
//               drawId: "717571",
//               closingDate: "2024-08-03T00:28",
//               frequency: "DAILY",
//               dailyReference: 5,
//               drawingDate: "2024-08-03T00:30",
//               drawingName: "Nkobo",
//             },
//             taxAmount: 260
//           }
//         ]
//       }
//     }
//   ];
//   const strFragment = JSON.stringify(passedBets);
//   let allPassedBets = [].concat(JSON.parse(strFragment)).concat(JSON.parse(strFragment)).concat(JSON.parse(strFragment)).concat(JSON.parse(strFragment)).concat(JSON.parse(strFragment).slice(0, 2)); //22
//   allPassedBets.forEach((item, index) => { if (index >= 10) item.bookingId = "0123456789abcdef-" + index })
//   const totalElements = allPassedBets.length;
//   const totalPages = Math.ceil(totalElements / params.size);
//   allPassedBets = allPassedBets.slice(params.page * params.size, (params.page + 1) * params.size);
//   return { data: { content: allPassedBets, totalElements, totalPages } }
// }
