import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './supportPopup.style';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/root.reducer';
import SVGInfo from 'src/assets/icons/SVGInfo';
import SVGCross from 'src/assets/icons/SVGCross';
import SVGCopy from 'src/assets/icons/SVGCopy';
import { Link } from 'react-router-dom';

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText?: string;
  hideBackdrop?: boolean;
  gameTicketReference?: string;
  transactionId?: string;
}

function SupportPopup({
  onClose,
  open,
  buttonText,
  hideBackdrop,
  gameTicketReference,
  transactionId,
}: Props): JSX.Element {

  const theme = useTheme();
  const style = makeStyle(useTheme());
  const { t } = useTranslation();
  const supportData = useSelector((state: RootState) => state.app.config.support);

  const onCopyTextValue = (value) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <Popover
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop ?? true}
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Box sx={style.boxInfo}>
          <SVGInfo color={theme.palette.primary.main} />
        </Box>

        <Button
          disableRipple
          onClick={onClose}
          sx={style.crossButton}
        >
          <SVGCross width="28" height="28" color="#98A2B3" />
        </Button>
      </Box>

      <Box sx={style.supportPopupBox}>
        <Typography sx={style.title}>{t('Ticket.contact_support')}</Typography>

        <Typography sx={style.infoLabel}>
          {t('Ticket.email')}:
        </Typography>
        <Typography sx={style.infoValue}>
          <Link to={'mailto:' + supportData.email} style={{color: theme.palette.primary.main}}>
            {supportData.email}
          </Link>
        </Typography>

        <Typography sx={style.infoLabel}>
          Tel:
        </Typography>
        <Typography sx={style.infoValue}>
          <Link to={'tel:' + supportData.phoneNumber} style={{color: theme.palette.primary.main}}>
            {supportData.phoneNumberDisplay || supportData.phoneNumber}
          </Link>
        </Typography>

        {transactionId &&
          <Box>
            <Typography sx={style.infoLabel}>
              {t('Ticket.n_transaction')}:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={style.infoValue}>
                {transactionId.replace(/(.{4})/g, '$1 ')}
              </Typography>
              <Button onClick={() => onCopyTextValue(transactionId)} sx={style.copyButton} disableRipple>
                <SVGCopy />
              </Button>
            </Box>
          </Box>
        }
        {gameTicketReference &&
          <Box>
            <Typography sx={style.infoLabel}>
              {t('ticket')}:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={style.infoValue}>
                {gameTicketReference.replace(/(.{4})/g, '$1 ')}
              </Typography>
              <Button onClick={() => onCopyTextValue(gameTicketReference)} sx={style.copyButton} disableRipple>
                <SVGCopy />
              </Button>
            </Box>
          </Box>
        }
      </Box>

      <Box sx={style.boxButton}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          sx={style.button}
          onClick={onClose}
        >
          {buttonText || 'OK'}
        </Button>
      </Box>
    </Popover>
  );
}

export default SupportPopup;
