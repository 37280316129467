import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as BetBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Button, Typography, useTheme, useMediaQuery, Collapse, Popover } from "@mui/material";
import { makeStyle } from "./betTypePicker.style";
import SVGSession from "src/assets/icons/SVGSession";
import SVGSessionDesktop from "src/assets/icons/SVGSessionDesktop";
import SVGArrowDown from "src/assets/icons/SVGArrowDown";
import SVGInfo from "src/assets/icons/SVGInfo";
import { IBetType, IBetTypesByCategory } from "src/models/lottery/betType.models";

const BetTypePicker = ({
  goNextStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up('lg'));

  //====================================== States

  const betTypeList = useSelector((state: RootState) => state.game.betTypesActive);
  const betTypeListByCategory = useSelector((state: RootState) => state.game.betTypesByCategory);
  const [categorySelected, setCategorySelected] = useState('');
  const [modalRulesIsOpen, setModalRulesOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(true);

  //====================================== Effects

  useEffect(() => {
    setShowCategories(betTypeListByCategory?.length > 1);
  }, [betTypeListByCategory])

  //====================================== Functions

  const onOpenCategory = (betTypesCat: IBetTypesByCategory) => {
    if (categorySelected == betTypesCat.category)
      setCategorySelected('')
    else
      setCategorySelected(betTypesCat.category)
  }

  const onBetTypeSelect = (betType) => {
    dispatch(BetBuilderActions.set_betType_selected(betType));
    goNextStep();
  }

  //====================================== Render

  const renderCategory = (betTypesCat: IBetTypesByCategory, key: number) => {

    return (
      <Box
        key={key}
      >
        {showCategories &&
          <Button
            disableRipple
            sx={[style.betTypeCollapse, categorySelected === betTypesCat.category && style.betTypeCollapseOpen]}
            onClick={() => onOpenCategory(betTypesCat)}
          >
            <Typography sx={style.betTypeCollapseText}>
              {t(betTypesCat.category)}
            </Typography>
            <Box sx={[style.categoryArrowIcon, categorySelected === betTypesCat.category && style.categoryArrowIconOpen]}>
              <SVGArrowDown />
            </Box>
          </Button>
        }
        <Collapse in={!showCategories || categorySelected === betTypesCat.category} timeout="auto">
          {betTypesCat.betTypes.map((betType, key2) => (
            renderBetTypeButton(betType, key2)
          ))}
        </Collapse>
      </Box>
    )
  }

  const renderBetTypeButton = (betType: IBetType, key: number) => {

    const maxGainValue: number = Math.max(...betType.listOfPayLines.map(elem => elem.gainValue), 0) || 0;

    return (
      <Box sx={style.betTypeButtonBox} key={key}>
        <Button disableRipple sx={style.betTypeButtonLeft} onClick={() => onBetTypeSelect(betType)}>
          <Typography sx={style.betTypeButtonTextLeft}>{betType.name}</Typography>
          <Typography sx={style.betTypeButtonTextLeft}>x{maxGainValue}</Typography>
        </Button>
        <Button
          disableRipple
          sx={style.betTypeButtonRight}
        >
          <SVGInfo color={'#000'} />
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={style.container}>
      <Popover
        open={modalRulesIsOpen}
        onClose={() => setModalRulesOpen(false)}
        sx={style.modalRules}
        hideBackdrop
        disableScrollLock={true}
        anchorReference={"none"}
      >
        <Typography>TODO: RULES OF THE GAME</Typography>
      </Popover>

      <Box sx={style.body}>

        <Box sx={style.topBoxContainer}>
          <Typography sx={style.topBoxTitleText}>{t('gameMode')}</Typography>
          {isDesktop ? <SVGSessionDesktop /> : <SVGSession />}
        </Box>

        <Box sx={style.betTypeListContainer}>
          {betTypeListByCategory.map((betTypesCat, key) => (
            renderCategory(betTypesCat, key)
          ))}
        </Box>

      </Box>

    </Box>
  )
}

//======================================
export default BetTypePicker;