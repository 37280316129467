import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  boxButton: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '8px',
  },
  button: {
    flex: 1,
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    minWidth: '80px',
    borderRadius: '8px',
    '& :hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(43 43 43 / 50%)',
    backdropFilter: 'blur(8px)',
    '& .MuiPaper-root': {
      borderRadius: '8px',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px',
      maxWidth: '468px',
      minWidth: '80%',
      padding: '16px',
      [theme.breakpoints.up('lg')]: {
        minWidth: '400px',
      }
    }
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '16px'
  },
  supportPopupBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoLabel: {
    color: '#333',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px'
  },
  infoValue: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#878787',
    marginBottom: '16px'
  },
  boxInfo: {
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primaryShades[50],
    borderRadius: '50px'
  },
  crossButton: {
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '0px'
  },
  copyButton: {
    height: '24px',
    minWidth: '0px',
    padding: '0px',
  },
});