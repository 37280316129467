import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    padding: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    borderRadius: '24px',
    background: '#FFF',
    boxShadow: '0px 6px 12px 0px #27272714',

  },
  containerSuccess: {
    border: `1px solid #32CD32`,
  },
  containerError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  errorButton: {
    borderRadius: '32px',
    backgroundColor: theme.palette.error.main,
    width: '100%',
    padding: '8px',
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
    "&:active": {
      backgroundColor: theme.palette.error.main,
    }
  },
  errorButtonTxt: {
    textTransform: 'none',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 700
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '12px',
    fontWeight: 400,
    paddingTop: '24px',
    marginBottom: '16px'
  },
  // STATUS
  statusContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '32px',
    padding: '8px 16px',
    width: 'fit-content',
    marginBottom: '16px'
  },
  statusContainerWon: {
    border: '1px solid #ABEFC6',
    backgroundColor: '#ECFDF3',
  },
  statusContainerLost: {
    border: '1px solid #FECDCA',
    backgroundColor: '#FEF3F2',
  },
  statusContainerCanceled: {
    border: '1px solid #E4E7EC',
    backgroundColor: '#F9FAFB',
  },
  statusText: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
  },
  statusTextWon: {
    color: "#067647"
  },
  statusTextLost: {
    color: "#B42318"
  },
  statusTextCanceled: {
    color: '#344054',
  },
  //===================================
  // HEADER
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
  headerLeft: {

  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#272727',
  },
  date: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#BBB',
  },
  headerRight: {
    textAlign: 'right',
  },
  headerActionButton: {
    width: '40px',
    minWidth: '40px',
  },
  //===================================
  // SELECTION
  selection: {
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    padding: '12px 16px',
  },
  selectionDrawCount: {
    background: '#272727',
    borderRadius: '8px',
    marginBottom: '8px',
    padding: '8px',
    width: 'fit-content',
  },
  selectionDrawCountText: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 700,
  },
  selectionNumberList: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  selectionNumberBox: {
    width: 40,
    height: 40,
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: '8px',
    marginTop: '8px'
  },
  selectionNumberBoxWin: {
    backgroundColor: '#32CD32'
  },
  selectionNumberBoxLost: {
    backgroundColor: theme.palette.error.main
  },
  selectionNumberExtraGridBox: {
    backgroundColor: '#4682b4',
    marginLeft: '8px',
  },
  selectionNumberText: {
    fontWeight: 700,
    color: '#FFF',
    fontSize: 16,
  },
  selectionTitle: {

  },
  selectionTitleText: {
    color: '#333',
    fontSize: '15px',
    fontWeight: 700,
  },
  selectionTitleSubText: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#BBB',
  },
  //===================================
  // DETAILS
  detailsContainer: {
    marginTop: '16px',
  },
  detailsContainerExpandButton: {
    padding: '12px',
    backgroundColor: '#272727',
    borderRadius: '32px',
    width: '100%',
    justifyContent: 'space-between',
    "&:hover, &:active": {
      backgroundColor: '#272727',
    },
  },
  detailsContainerExpandButtonText: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    paddingLeft: '8px',
  },
  detailsContainerIcon: {
    display: 'flex',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  detailsContainerIconOpen: {
    transform: 'rotate(180deg)',
  },
  detailsContent: {
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
    marginTop: '8px',
  },
  detailsLineList: {
    padding: '8px',
    paddingBottom: 0,
  },
  detailsLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingTop: '4px',
    paddingBottom: '16px',
  },
  detailsLineLabel: {
    color: '#878787',
    fontSize: '14px',
    fontWeight: 500,
  },
  detailsLineValue: {
    color: '#878787',
    fontSize: '14px',
    fontWeight: 500,
  },
  detailsBoxTaxLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  detailsLineTaxLabel: {
    color: '#F79009',
    marginRight: '8px',
  },
  detailsLineTaxValue: {
    color: '#F79009',
  },
  detailsTaxButton: {
    minWidth: '0px',
    padding: '0px',
  },
  detailsLineStrong: {
    backgroundColor: '#32CD32',
    padding: '12px 8px 12px 8px',
    borderRadius: '6px',
    marginTop: '12px',
  },
  detailsLineStrongLabel: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 600,

  },
  detailsLineStrongValue: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 600,
  },
  buttonTooltip: {
    padding: '0px',
    justifyContent: 'end',
    flex: 0.8,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    "&:active": {
      backgroundColor: 'transparent'
    }
  },
  toolTip: {
    '& .MuiTooltip-arrow': {
      left: "70px !important",
      bottom: "1px !important"
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      color: '#667085',
      padding: '12px',
      boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      fontSize: "12px",
      fontWeight: 700,
      marginBottom: '0px !important'
    },
    '& .css-kudwh-MuiTooltip-arrow': {
      color: '#FFF'
    }
  },
})