import commonStyle from "src/common/style/commonStyle";
import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '148px',
      paddingRight: '148px'
    }
  },
  header: {
    margin: '8px 16px',
    [theme.breakpoints.up('lg')]: {
      margin: '24px',
    },
  },
  content: {
    padding: '4px 0px',
    overflowY: 'auto',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      ...commonStyle.slimScrollBar(theme),
      paddingRight: '50px',
    },
  },
  //TABS
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start',
        gap: "24px",
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '16px',
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        minWidth: '124px',
        flex: 0,
      },
    },
  },
  tab: {
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  tabText: {
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none',
    color: '#BBBBBB',
  },
  tabTextSelected: {
    color: '#FFF'
  },
  // LIST
  listContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '8px',
  },
  ticketsEmpty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px'
  },
  ticketsEmptyTxt: {
    marginTop: '16px',
    fontSize: 16,
    fontWeight: 400,
    color: "#BBBBBB",
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  //FILTER
  boxFilter: {
    margin: '12px 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  boxFilterTxt: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000'
  },
  //PAGINATION
  showMoreButton: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: "transparent",
    '&:hover': { backgroundColor: "transparent" },
    '&:disabled': { backgroundColor: "transparent" },
    minHeight: '40px',
    padding: 0,
    width: '100%',
    textTransform: 'none',
    marginBottom: '8px'
  }, 
  showMoreButtonText: {
    color: "#878787",
    fontWeight: 500,
    fontSize: '14px',
    textDecoration: 'underline'
  },
  loaderBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px'
  }
})