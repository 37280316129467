import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { Box, Button, Typography, useTheme, Tooltip } from "@mui/material";
import { makeStyle } from "./basketItem.style";
import { EBasketStatus, IBasketItem } from "src/models/lottery/basket.model";
import dayjs from "dayjs";
import SVGPen from "src/assets/icons/SVGPen";
import SVGTrash from "src/assets/icons/SVGTrash";
import SVGChevronDown from "src/assets/icons/SVGChevronDown";
import { zeroFill } from "src/common/utils/format.utils";
import SVGInfo from "src/assets/icons/SVGInfo";
import TaxesPopup from "src/component/core/taxesPopup/taxesPopup.comp";

type Props = {
  item: IBasketItem,
  onEditItem: () => void,
  onRemoveItem: () => void,
  index: number,
}

const BasketItem = ({
  item,
  onEditItem,
  onRemoveItem,
  index
}: Props): JSX.Element => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);

  //====================================== States

  const [showDetails, setShowDetails] = useState(false);
  const [errorTextKey, setErrorTextKey] = useState('');

  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  const useTaxes: boolean = useSelector((state: RootState) => state.taxes.useTaxes);
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);
  const gameName = useSelector((state: RootState) => state.game.game.name);
  const [showTaxesPopup, setShowTaxesPopup] = useState<boolean>(false);

  //====================================== Effects

  useEffect(() => {
    setErrorTextKey(computeErrorTextKey(item));
  }, [item])

  //====================================== Functions

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  }

  const openTaxesPopup = () => {
    setShowTaxesPopup(true)
  }

  const computeErrorTextKey = (_item: IBasketItem): string => {
    switch (_item.status) {
      case EBasketStatus.valid: return '';
      case EBasketStatus.draw_closed: return 'Error.draw_bet_closed';
      case EBasketStatus.not_enough_draws: return 'Error.not_enough_draws';
      default:
        return '';
    }
  }

  //====================================== Render

  return (
    <Box sx={[style.container, item.status != EBasketStatus.valid && style.containerError]}>

      {/* HEADER */}
      <Box sx={style.header}>
        <Box sx={style.headerLeft}>
          <Typography sx={style.title}>
            {betTypesActive?.length == 1 ? gameName : item.betTypeName}
          </Typography>
          <Typography sx={style.date}>
            {dayjs(item.draw.drawingDate).format('DD/MM/YY - HH:mm')}
          </Typography>
        </Box>
        <Box sx={style.headerRight}>
          <Button sx={style.headerActionButton} onClick={onEditItem}>
            <SVGPen />
          </Button>
          <Button sx={style.headerActionButton} onClick={onRemoveItem}>
            <SVGTrash />
          </Button>
        </Box>
      </Box>

      {/* SELECTION */}
      <Box sx={style.selection}>

        {item.nbConsecutiveDraws > 0 &&
          <Box sx={style.selectionDrawCount}>
            <Typography sx={style.selectionDrawCountText}>
              {item.nbConsecutiveDraws} {t(item.nbConsecutiveDraws > 1 ? 'draws' : 'draw').toLowerCase()}
            </Typography>
          </Box>
        }

        <Box sx={style.selectionTitle}>
          <Typography sx={style.selectionTitleText}>
            {item.selectionListByGrid[0].length} {t(item.selectionListByGrid[0].length > 1 ? 'numbers' : 'number').toLowerCase()}
            {item.combinationCount > 1 &&
              <Typography sx={style.selectionTitleText} component="label">
                ,&nbsp;
                {item.combinationCount} {t('combinations').toLowerCase()}*
              </Typography>
            }
          </Typography>
          {item.combinationCount > 1 &&
            <Typography sx={style.selectionTitleSubText}>
              *&nbsp;{t('Bet.active_permutation')}
            </Typography>
          }
        </Box>

        <Box sx={style.selectionNumberList}>
          {item.selectionListByGrid.map((G, gkey) => G.map((numb, key) => (
            <Box key={gkey + '.' + key} sx={[style.selectionNumberBox, gkey > 0 && style.selectionNumberExtraGridBox]}>
              <Typography sx={style.selectionNumberText}>
                {zeroFill(numb)}
              </Typography>
            </Box>
          )))}
        </Box>

      </Box>

      <Box sx={style.detailsContainer}>
        <Button
          sx={style.detailsContainerExpandButton}
          onClick={toggleDetails}
          disableRipple
        >
          <Typography sx={style.detailsContainerExpandButtonText}>
            {t('Bet.details')}
          </Typography>
          <Box sx={[style.detailsContainerIcon, showDetails && style.detailsContainerIconOpen]}>
            <SVGChevronDown width="12" height="12" color="#FFF" />
          </Box>
        </Button>
        {showDetails &&
          <Box sx={style.detailsContent}>
            <Box sx={style.detailsLineList}>
              <Box sx={style.detailsLine}>
                <Typography sx={style.detailsLineLabel}>
                  {t('stake')}
                </Typography>
                <Typography sx={style.detailsLineValue}>
                  {item.stake.toLocaleString()} {currency}
                </Typography>
              </Box>
              <Box sx={style.detailsLine}>
                <Typography sx={style.detailsLineLabel}>
                  Total
                </Typography>
                <Typography sx={style.detailsLineValue}>
                  {item.price.toLocaleString()} {currency}
                </Typography>
              </Box>
              {useTaxes &&
                <Box sx={style.detailsLine}>
                  <Typography sx={style.detailsLineLabel}>
                    {t('Bet.max_gain_gross')}
                  </Typography>
                  <Typography sx={style.detailsLineValue}>
                    {item.potentialAward.toLocaleString()} {currency}
                  </Typography>
                </Box>
              }
              {useTaxes &&
                <Box sx={style.detailsLine}>
                  <Box sx ={style.detailsBoxTaxLabel}>
                    <Typography sx={style.detailsLineTaxLabel}>
                      {t('tax')}
                    </Typography>
                    <Button disableRipple onClick={openTaxesPopup} sx={style.detailsTaxButton}>
                      <SVGInfo color="#F79009" width="16" height="16" />
                    </Button>
                    
                  </Box>
                  <Typography sx={[style.detailsLineValue, style.detailsLineTaxValue]}>
                    {item.taxAmount == 0 ? 0 : (-item.taxAmount).toLocaleString()} {currency}
                  </Typography>
                </Box>
              }
            </Box>

            <Box sx={[style.detailsLine, style.detailsLineStrong]}>
              <Typography sx={style.detailsLineStrongLabel}>
                {t('Bet.max_gain_net')}
              </Typography>
              <Typography sx={[style.detailsLineValue, style.detailsLineStrongValue]}>
                {item.potentialAwardNet.toLocaleString()} {currency}
              </Typography>
            </Box>
          </Box>
        }
      </Box>

      {errorTextKey.length > 0 &&
        <Typography sx={style.errorText}>
          *{t(errorTextKey)}
        </Typography>
      }

      <TaxesPopup
        open={showTaxesPopup}
        onClose={() => setShowTaxesPopup(false)}
        buttonText={t("understood")}
      />
    </Box >
  )
}

//======================================
export default BasketItem;